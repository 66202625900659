<template>
  <div class="cursos-panel">
    <div id="cursos" class="cursos">
      <div class="content-course">
        <span class="white montserrat-bold title-course">Clases</span>
        <span class="white m-left montserrat-bold title-course"
          >activas</span
        >

        <div
          v-for="(curso, index) in $store.getters.allCoursesFormated"
          :key="index"
          class="cursosContainer">
          <div
            v-if="curso.active && curso.coursetype == 'groupcourse'"
            class="cursoBox"
          >
            <div  class="content-box-course">
              <div class="image-place-course">
                <img :src="curso.location.picture" />
              </div>

              <div class="center">
                <span class="montserrat-bold is-size-medium-text"> {{ curso.name }} <b class="montserrat-extrabold">·</b> {{ curso.classprice}}€</span>
                <div>
                  <span class="montserrat-bold is-size-text-normal"
                    >Mentor:
                  </span>
                  <span class="montserrat is-size-text-normal">
                    {{ curso.mentor }}</span
                  >
                  <span class="montserrat-bold is-size-text-normal ml-3"
                    >Lugar:</span
                  >
                  <span class="montserrat is-size-text-normal">
                    {{ curso.location.name }}</span
                  >
                </div>

                <div class="mt-4">
                  <p class="montserrat-bold is-size-text-normal">Horario:</p>
                  <span
                    class="montserrat mr-2 mobile-text"
                    v-for="(dia, index2) in curso.horario"
                    :key="index2"
                    > <b class="montserrat-extrabold">·</b> {{ dia }}
                  </span>
                </div>
                <div class="mt-4">
                  <p class="montserrat-bold is-size-text-normal">Condiciones</p>
                  <span class="montserrat mr-2 mobile-text"> 
                    <p>{{ curso.conditions }} </p> 
                  </span>
                </div>
              </div>

              <div>
                <button
                  class="button-primary-orange-small"
                  @click.prevent="modalReserva(curso)"
                >
                  Reservar clase
                </button>
                <button
                  class="button-primary-orange-small mleft-button"
                  @click.prevent="newBono(curso)"
                >
                  Comprar Bono
                </button>
              </div>
              
            </div>
            <div class="availableBonos mt-5"
            v-if="$store.state.mybonos && $store.state.mybonos.filter((bono) => bono.course_id._id == curso._id && bono.bookings.length < bono.max_bookings).length > 0">
              
                Bonos disponibles: 
                <span
                  v-for="(bono, index3) in $store.state.mybonos.filter((bono) => bono.course_id._id == curso._id && bono.bookings.length < bono.max_bookings)"
                  :key="index3"
                  style="display: inline-block;"
                  @click.prevent="modalReservaBono(bono)"
                >
                  <div class="tag-bonos opensans is-size-small-text ml-2">
                    <span class="">{{ bono.profile_id.firstname }} {{ bono.profile_id.lastname }} {{ bono.max_bookings-bono.bookings.length }}/{{ bono.max_bookings }}</span>
                  </div>
                </span>

            </div>
              
          </div>
            
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CursosPanel',
  methods: {
    // Reservar
    modalReserva (curso) {
      const reservation = {
        course: curso,
        with_bono: false
      }
      this.$store.commit('updateCurrentCourse', curso._id)
      this.$store.commit('updateReservation', reservation)
      this.$store.commit('incrementBookingStep')
      this.$store.commit('incrementBookingStep')
      this.$store.commit('openModalR', 'newbooking')

    },
    modalReservaBono (bono) {
      const reservation = {
        course: bono.course_id,
        student: bono.profile_id,
        with_bono: true,
        price: 0
      }
      this.$store.commit('updateCurrentCourse', bono.course_id._id)
      this.$store.commit('updateReservation', reservation)
      this.$store.commit('incrementBookingStep')
      this.$store.commit('incrementBookingStep')
      this.$store.commit('openModalR', 'newbooking')
    },
    // NewBono
    newBono (curso) {
      const newBono = {course: curso}
      this.$store.commit('updateCurrentCourse', curso._id)
      this.$store.commit('updateBonoState', newBono)
      this.$store.commit('openModalR', 'newbono')
    },
  }
}
</script>

<style scoped>
.cursos-panel {
  margin-top: 65px;
}

/* COURSE */

.content-course {
  padding-top: 95px;
  width: 75%;
  margin: auto;
}

.cursosContainer {
  margin-top: 10px;
}

.paragraph-text {
  margin-top: 25px;
  max-width: 658px;
  line-height: 25px;
  opacity: 0.7;
  margin-bottom: 70px;
}

.title-course {
  font-size: 25px;
}

.cursos {
  width: 100%;
  background-color: #171616;
  padding-bottom: 100px;
}

.cursoBox {
  width: 100%;
  background-color: rgb(140, 140, 140, 0.19);
  color: #fafafa;
  margin-top: 10px;
  padding-bottom: 15px;
  padding-top: 15px;
}

.content-box-course {
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
}

.image-place-course {
  object-fit: cover;
  display: block;
}

.image-place-course img {
  min-width: 120px;
  max-width: 130px;
  border-radius: 2px;
}

.center {
  max-width: 40%;
  min-width: 40%;
  margin-left: 17px;
  margin-right: 17px;
}

.m-left {
  margin-left: 10px;
}

.mobile-text {
  font-size: 13px;
  opacity: 0.6;
}

.mleft-button {
  margin-left: 6px;
}

.availableBonos {
  width: 90%;
  margin: auto;
  padding-top: 20px;
  padding-bottom: 10px;
  border-top: 1px solid #171616;
}

.tag-bonos {
  padding: 5px;
  border-radius: 5px;
  background-color: rgba(242, 151, 19, 0.5);
  display: inline;
  cursor: pointer;
}

@media only screen and (max-width: 997px) {
  .content-course {
    padding-top: 80px;
    width: 90%;
  }


  .paragraph-text {
    max-width: 542px;
  }

  .content-box-course {
    width: 95%;
  }

  .center {
    max-width: 46%;
    min-width: 49%;
  }
}

@media only screen and (max-width: 928px) {
  
  .image-place-course {
    display: none;
  }

  .center {
    max-width: 64%;
    min-width: 64%;
  }

  .paragraph-text {
    max-width: 474px;
  }
}

@media only screen and (max-width: 894px) {
  .content-box-course {
    display: block;
  }

  .center {
    max-width: 90%;
  }

  .mleft-button {
    margin-left: 15px;
    margin-top: 15px;
  }

  .content-course {
    padding-top: 42px;
  }
}
</style>