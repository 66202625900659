<template>
  <div id="date">
    <div class="container-date">
      <p class="montserrat-medium black title-step">
        2. Elige las semanas que quieren reservar:
      </p>


      <div class="list-dates"
      v-if="this.$store.getters.currentCourse.weekprice1 || this.$store.getters.currentCourse.weekprice2 || this.$store.getters.currentCourse.weekprice3 || this.$store.getters.currentCourse.weekprice4">
        <div
          v-for="(week, index) in this.availableWeeks"
          :key="index"
          class="content-date"
          :class="{
            bookingSelection: weekCheckBox && weekCheckBox.some(item => shallowEqual(item, week)),
            disabled: !classInWeekAvailable(week)
          }"
        >
          <b-field>
            <b-checkbox
            v-model="weekCheckBox"
            :native-value="week"
            @change.native="updateCurrentPrice">
              <!-- DATE CLASS -->
              <span class="montserrat black is-size-small-text"> {{week[0].format('D [d]e MMMM')}} al {{week[1].format('D [d]e MMMM')}}</span>
            </b-checkbox>
          </b-field>
        </div>
        <button
          class="button-primary-orange-small continue-button"
          style="width: 100%"
          @click.prevent="saveWeekSelection"
          :class="{ 'disabled': weekCheckBox.length == 0}"
        >
          <span class="montserrat-bold" style="float:left; margin-left: 12px">Continuar</span> <span class="montserrat-bold" style="float:right; margin-right: 25px">{{this.currentPrice}}€</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import store from '../../store';
import dayjs from 'dayjs'

var localizedFormat = require('dayjs/plugin/localizedFormat')
var isBetween = require('dayjs/plugin/isBetween')
dayjs.extend(isBetween)
dayjs.extend(localizedFormat)
var locale = require('dayjs/locale/es')
dayjs.locale('es')


export default {
  name: 'ChooseWeek',

  // CREATED
  async created () {
    await this.$store.dispatch('updateCourseClasses', this.$store.state.weeklyBookingState.course._id)
    this.availableWeeks = this.classWeeks(this.$store.state.courseClasses)

  },
  mounted() {
    if(this.$store.state.weeklyBookingState.weeks) this.weekCheckBox = this.$store.state.weeklyBookingState.weeks.map(v => v)
    this.updateCurrentPrice()
  },
  data () {
    return {
      section: window.location.pathname.split('/')[2],
      weekCheckBox: [],
      availableWeeks: null,
      currentPrice: 0
    }
  },

  methods: {
    saveWeekSelection() {
      let weeklyBookingState = this.$store.state.weeklyBookingState
      weeklyBookingState.weeks = this.weekCheckBox

      this.updateCurrentPrice()
      weeklyBookingState.price = this.currentPrice
      
      //Add all classes from selected weeks to a list
      weeklyBookingState.classes = this.classesInWeeks(this.weekCheckBox)
      
      this.$store.commit("updateWeeklyBookingState", weeklyBookingState)
      this.$store.commit("incrementWeeklyBookingStep")

    },

    classInWeekAvailable(week) {
      let classesInWeek = this.classesInWeeks([week])
      const unavailableClassesInWeek = classesInWeek.some(clase => clase.bookings.length >= clase.maxstudents)
      const alreadyBookedAllClassesInWeek = classesInWeek.every(clase => {
        return clase.bookings.some(booking => booking.profile_id == this.$store.state.weeklyBookingState.student._id)
      })

      if (classesInWeek.length == 0 || unavailableClassesInWeek || alreadyBookedAllClassesInWeek) return false
      return true
    },

    classesInWeeks(weeks) {
      let classes = []

      weeks.forEach(week => {
        this.$store.state.courseClasses.forEach(clase => {
          if (dayjs(clase.startclass).isBetween(week[0], week[1])) classes.push(clase)
        })
      })
      
      return classes
    },

    shallowEqual(week1, week2) {
      if (week1[0].isSame(week2[0]) && week1[1].isSame(week2[1])) return true
    },

    updateCurrentPrice() {
      this.currentPrice = 0
      let numberOfWeeks = this.weekCheckBox.length
      if (numberOfWeeks == 0) return //Nothing selected
      
      /*
      // 1. Calculate total number of classes chosen
      let totalClasses = 0
      this.weekCheckBox.forEach(week => { //For every selected week
        let classesInWeek = this.classesInWeek(week) //Get all the classes in that week
        totalClasses += classesInWeek.length
      })
      */

      // 2. Calculate cheapest price
      while (numberOfWeeks >= 0 && (this.$store.getters.currentCourse.weekprice4 || this.$store.getters.currentCourse.weekprice3 || this.$store.getters.currentCourse.weekprice2 || this.$store.getters.currentCourse.weekprice1)) {
        if (this.$store.getters.currentCourse.weekprice4 != null && numberOfWeeks >= 4) {
          numberOfWeeks -= 4
          this.currentPrice += this.$store.getters.currentCourse.weekprice4
        } else if (this.$store.getters.currentCourse.weekprice3 != null && numberOfWeeks >= 3) {
          numberOfWeeks -= 3
          this.currentPrice += this.$store.getters.currentCourse.weekprice3
        } else if (this.$store.getters.currentCourse.weekprice2 != null && numberOfWeeks >= 2) {
          numberOfWeeks -= 2
          this.currentPrice += this.$store.getters.currentCourse.weekprice2
        } else if (this.$store.getters.currentCourse.weekprice1 != null && numberOfWeeks >= 1) {
          numberOfWeeks -= 1
          this.currentPrice += this.$store.getters.currentCourse.weekprice1
        } else {
          break;
        }
      }
      this.currentPrice = this.calculatePrice(this.currentPrice)
    },

    calculatePrice(defaultPrice) {
      let course = this.$store.state.weeklyBookingState.course
      let profile = this.$store.state.weeklyBookingState.student

      if (course.residentDiscount > 0 && profile.residente) return defaultPrice*((100-course.residentDiscount)/100)
      if (course.federadoDiscount > 0 && profile.federado) return defaultPrice*((100-course.federadoDiscount)/100)
      else return defaultPrice
    },

    classWeeks(classes) {
      // weeks = [[start, end], [start, end]] etc.
      // 1. from which to which week?
      // 2. insert weekstart & weekend into array
      //let minWeek = dayjs(classes[0].start).startOf('week')
      //let maxWeek = dayjs(classes[classes.length - 1].end).endOf('week')
      let weeks = []
      //Check if the currentCourse has a weekprice1, weekprice2, weekprice3, weekprice4
      if (!this.$store.getters.currentCourse.weekprice1 && !this.$store.getters.currentCourse.weekprice2 && !this.$store.getters.currentCourse.weekprice3 && !this.$store.getters.currentCourse.weekprice4) return []
      classes.forEach(clase => {
        if (dayjs(clase.startclass).isAfter(dayjs())) {
          let monday = dayjs(clase.startclass).startOf('week')
          let sunday = dayjs(clase.endclass).endOf('week')
          let week = [monday, sunday]
          if (weeks.length == 0) weeks.push(week)
        else if (!this.shallowEqual(week, weeks[weeks.length - 1])) weeks.push(week)
        } 
      })
      return weeks
    },
  },
};
</script>

<style scoped>
.title-step {
  font-size: 15px;
  margin-top: 60px;
}

.disabled {
  background-color: #eaeaea !important;
  pointer-events: none;
  opacity: 0.3 !important;
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.list-dates {
  margin-top: 20px;
}

.content-date {
  background-color: #f5f3f3;
  padding-top: 12px;
  padding-left: 12px;
  padding-bottom: 12px;
  border-radius: 2px;
  max-width: 413px;
  margin-bottom: 10px;
}

.continue-button {
  padding-top: 12px;
  padding-left: 12px;
  padding-bottom: 12px;
  max-width: 413px;
  margin-bottom: 10px;
}

.content-date:hover {
  background-color: #fbdfb6;
}

</style>