import { render, staticRenderFns } from "./CampamentosActivos.vue?vue&type=template&id=5b7ad886&scoped=true"
import script from "./CampamentosActivos.vue?vue&type=script&lang=js"
export * from "./CampamentosActivos.vue?vue&type=script&lang=js"
import style0 from "./CampamentosActivos.vue?vue&type=style&index=0&id=5b7ad886&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b7ad886",
  null
  
)

export default component.exports